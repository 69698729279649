module.exports = {
  pages: [
    { label: 'Home', link: '/', alpha: true },
    // { label: 'Trial', link: '/trial', alpha: true },
    // { label: 'Pricing', link: '/pricing', alpha: false },
    { label: 'About', link: '/about', alpha: true },
    { label: 'Contact', link: '/contact', alpha: false },
    { label: 'For Swimmers', link: '/conduct', alpha: false },
    // { label: 'Blog', link: '/blog', alpha: false },
  ],
  siteName: `Sea Dragons Aquatic Academy`, // This appears after page title behind a pipe
  siteDescription: `Swim at your potential. Learn with the best.`, // This is used if SEO component has no "description" prop - Use business value proposition
  siteUrl: `https://www.seadragons.com.my`,
  author: `tom@magentastudio.com.sg`,
  contact: {
    name: `Sea Dragons Aquatic Academy`,
    email: `enquiry@seadragons.com.my`,
    address: {
      streetAddress: `No. 38, Persiaran Skudai 8`,
      addressLocality: `Pusat Perusahaan Skudai 8`,
      addressRegion: `Johor`,
      postalCode: `81300 Skudai`,
      addressCountry: `MY`,
      addressCountryLong: `Malaysia`,
    },
    telephone: `+60 19-8183800`,
    whatsapp: `+60 19-8183800`,
    googleMap: `https://goo.gl/maps/5faUow6tZNLPwEcMA`,
    latitude: 1.529119,
    longitude: 103.683311,
  },
  business: {
    serviceType: `Aquatic Academy`, // User defined business type
    uen: `xxxxxxxxx`,
    dayOfWeek: [`Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`, `Sunday`],
    dayOfWeekRange: `Mon-Sun`,
    opens: `08:00`,
    closes: `22:00`,
    priceRange: `$$$`,
    ageRestriction: 100,
    hours: [
      { day: 'Mon', duration: '2pm - 9pm' },
      { day: 'Tue', duration: '8am - 10pm' },
      { day: 'Wed', duration: '8am - 9pm' },
      { day: 'Thu', duration: '8am - 10pm' },
      { day: 'Fri', duration: '8am - 9pm' },
      { day: 'Sat', duration: '7am - 8pm' },
      { day: 'Sun', duration: '7am - 8pm' },
    ],
  },
  eCommerce: {
    service: 'Shopify', // Shopify | Stripe
  },
  marketing: ['Facebook', 'Instagram'], // Facebook | Instagram | LinkedIn | Google AdWords | Bing | Youtube | Twitter
  socialMedia: {
    facebook: `https://www.facebook.com/pg/seadragons.aa`,
    instagram: `https://www.instagram.com/sea_dragons_aquatic/`,
    youtube: `https://www.youtube.com/channel/UCmQTtKW5mGi7MzAjREAykag/`,
    twitter: `https://twitter.com/`,
  },
  remoteAssetLogo: `https://res.cloudinary.com/dul1gpfjv/image/upload/v1584606036/Do%20Not%20Delete/logo_xwz9cl.png`,
  cookieLifeInDays: 1,
};
