import * as React from "react";

function SvgPhone(props) {
  return (
    <svg viewBox="0 0 66.87 90" {...props}>
      <g data-name="Layer 2">
        <path
          d="M9.9 2.1A17.6 17.6 0 0122.28.42a202.06 202.06 0 005.77 22.65l-11.94 5.62C14.46 42 27 63.66 39.31 68.86l10.83-7.53a202 202 0 0016.73 16.33 17.56 17.56 0 01-7.64 9.88C28.13 105.49-21.2 20.05 9.9 2.1z"
          fillRule="evenodd"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgPhone;

