import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import { Modal, Loading } from '../primitives';
import ContactForm from './ContactForm';

const Section = styled.section`
  position: relative;
`;

const Block = styled.div`
  position: relative;
  overflow: hidden;
  @media (min-width: 640px) {
    padding: 4rem 0;
  }
`;

const Background = styled.div`
  background: #ebf8ff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fefeff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%23bee3f8' fill-opacity='0.58' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E");
  transform: translate(-40rem, -30rem);
`;

const FormWrapper = styled.div`
  position: relative;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  background: #edf2f7;

  #contact-form-wrapper {
    #grid-firstname {
      grid-area: firstname;
    }
    #grid-lastname {
      grid-area: lastname;
    }
    #grid-email {
      grid-area: email;
    }
    #grid-phone {
      grid-area: phone;
    }
    #grid-compamy {
      grid-area: company;
    }
    #grid-message {
      grid-area: message;
    }
    #grid-checkbox {
      grid-area: checkbox;
    }
    #grid-button {
      grid-area: button;
      margin: 2rem 0 0 0;
    }

    display: grid;
    grid-template-areas:
      'firstname'
      'lastname'
      'email'
      'phone'
      'company'
      'message'
      'checkbox'
      'button';

    @media (min-width: 480px) {
      grid-column-gap: 1rem;
      grid-template-areas:
        'firstname lastname'
        'email email'
        'phone company'
        'message message'
        'checkbox checkbox'
        'button button';
    }
  }
  padding: 4rem 1rem;
  max-width: 632px;
  margin: 0 auto;
  @media (min-width: 648px) {
    padding: 4rem 3rem;
  }
`;

const Form = () => {
  //

  const [isModalOpen, setModal] = useState(false);

  return (
    <>
      <Section id="form">
        <Block>
          <Background />
          <FormWrapper>
            <ContactForm />
          </FormWrapper>
        </Block>
      </Section>
      <Modal open={isModalOpen}>
        <div>
          Hello
          <button onClick={() => setModal(false)}>Close</button>
        </div>
      </Modal>
    </>
  );
};

export default Form;

Form.propTypes = {
  //
};

Form.defaultProps = {
  //
};
