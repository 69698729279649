import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import { Input, Button, TextArea, Checkbox, Anchor, Dialog } from '../primitives';
import { contactFormValidation } from '../../utils';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const Wrapper = styled.div.attrs({ className: 'relative' })``;

const Message = styled.div`
  max-width: 400px;
  margin: 0 auto;
  h3 {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0 0 1rem 0;
  }
  p {
    margin: 0 0 2rem 0;
  }
`;

const ContactForm = () => {
  //
  const [isSuccessOpen, toggleSuccessDialog] = useState(false);
  const [isFailOpen, toggleFailDialog] = useState(false);

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY);

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',
          message: '',
          agree: false,
          captcha: false,
        }}
        validationSchema={contactFormValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            const res = await axios({
              method: 'post',
              url: `https://api.hsforms.com/submissions/v3/integration/submit/7320947/f96c416a-7a05-4cd3-a398-c2d0b1fcb2a5`,
              headers: { 'Content-Type': 'application/json' },
              data: {
                fields: [
                  {
                    name: 'email',
                    value: values.email,
                  },
                  {
                    name: 'firstname',
                    value: values.firstName,
                  },
                  {
                    name: 'lastname',
                    value: values.lastName,
                  },
                  {
                    name: 'phone',
                    value: values.phone,
                  },
                  {
                    name: 'company',
                    value: values.company,
                  },
                  {
                    name: 'message',
                    value: values.message,
                  },
                ],
              },
            });
            if (res.status === 200) {
              actions.resetForm();
              toggleSuccessDialog(true);
            }
          } catch (error) {
            toggleFailDialog(true);
          }
        }}
      >
        {({ values, handleChange, errors, setErrors, setFieldValue, isSubmitting }) => {
          //
          const resetError = field => {
            setErrors({ ...errors, [field]: '' });
          };

          const handleChangeMessage = e => {
            setFieldValue(e.target.name, e.target.value);
          };

          const Agreement = (
            <span>
              Agree to our{' '}
              <Anchor href={`${siteUrl}/privacy`} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Anchor>
            </span>
          );

          return (
            <Wrapper>
              <Form>
                <div id="contact-form-wrapper">
                  <div id="grid-firstname">
                    <Input
                      asterisk
                      labelText="First name"
                      inputName="firstName"
                      value={values.firstName}
                      errorText={errors.firstName}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-lastname">
                    <Input
                      asterisk
                      labelText="Last Name"
                      inputName="lastName"
                      value={values.lastName}
                      errorText={errors.lastName}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-email">
                    <Input
                      asterisk
                      labelText="Email"
                      inputName="email"
                      value={values.email}
                      errorText={errors.email}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-phone">
                    <Input
                      labelText="Phone"
                      inputName="phone"
                      value={values.phone}
                      errorText={errors.phone}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-compamy">
                    <Input
                      labelText="Company"
                      inputName="company"
                      value={values.company}
                      errorText={errors.company}
                      onChange={handleChange}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-message">
                    <TextArea
                      asterisk
                      asteriskColor="tomato"
                      placeholder="Do leave us a message"
                      labelText="Message"
                      inputName="message"
                      value={values.message}
                      errorText={errors.message}
                      rows="4"
                      onChange={e => handleChangeMessage(e)}
                      onFocus={e => {
                        resetError(e.target.name);
                      }}
                    />
                  </div>

                  <div id="grid-checkbox">
                    <div>
                      <Checkbox
                        asterisk
                        asteriskColor="tomato"
                        inputName="agree"
                        labelNode={Agreement}
                        value={values.agree}
                        errorText={errors.agree}
                        onChange={handleChange}
                        onFocus={e => {
                          resetError(e.target.name);
                        }}
                      />
                    </div>
                  </div>
                  <div id="grid-button">
                    <Button type="submit" label="Send message" disabled={isSubmitting}>
                      Send Form
                    </Button>
                  </div>
                </div>
              </Form>
            </Wrapper>
          );
        }}
      </Formik>
      <Dialog open={isSuccessOpen} close={toggleSuccessDialog}>
        <Message>
          <h3>Thank you for reaching out.</h3>
          <p>A member of our team will get in touch as soon as possible.</p>
        </Message>
      </Dialog>
      <Dialog open={isFailOpen} close={toggleFailDialog}>
        <Message>
          <h3>Something has gone wrong.</h3>
          <p>Please try again.</p>
        </Message>
      </Dialog>
    </>
  );
};

export default ContactForm;
