import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO, JsonLd } from '../components/primitives';
import { service } from '../schema/service';
import Form from '../components/_contact/Form';
import Map from '../components/_contact/Map';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Contact = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Contact Us" description="Contact Us. Submit feedback and enquries." />
      <Map />
      {/* <Form /> */}
      <JsonLd schema={service} />
    </motion.article>
  );
};

export default Contact;

Contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
