import * as React from "react";

function SvgWhatsapp(props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <g data-name="Layer 2">
        <path
          d="M24 0a24 24 0 11-13.2 44l-9.22 3 3-8.91A24 24 0 0124 0zm-6.7 12.19c-.46-1.11-.82-1.16-1.52-1.18h-.8a3.5 3.5 0 00-2.46.86 7.69 7.69 0 00-2.45 5.84c0 3.44 2.51 6.77 2.85 7.24s4.89 7.63 11.94 10.55c5.52 2.29 7.15 2.08 8.41 1.81 1.83-.4 4.13-1.75 4.71-3.39a5.94 5.94 0 00.41-3.33c-.17-.3-.63-.46-1.34-.82s-4.13-2-4.78-2.27a1.34 1.34 0 00-1.72.52 29 29 0 01-1.88 2.49 1.51 1.51 0 01-1.69.26 17.43 17.43 0 01-5.63-3.47 21.09 21.09 0 01-3.89-4.84c-.41-.7 0-1.11.28-1.49s.69-.75 1-1.16a4.16 4.16 0 00.78-1.1 1.44 1.44 0 00-.1-1.3c-.14-.41-1.55-3.82-2.13-5.22z"
          fillRule="evenodd"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgWhatsapp;

