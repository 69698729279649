import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Facebook from '../svg/Facebook';
import Instagram from '../svg/Instagram';
import YouTube from '../svg/YouTube';
import Email from '../svg/Email';
import GoogleMapPin from '../svg/GoogleMapPin';
import Clock from '../svg/Clock';
import Phone from '../svg/Phone';
import Whatsapp from '../svg/Whatsapp';

const QUERY = graphql`
  query {
    businessInfo: site {
      ...BusinessInfo
    }
  }
`;

const locationSDAA =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61285.781102860696!2d103.65325627378074!3d1.5223214070552622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da724caf8a6973%3A0xd707f88f0e00fbb5!2sSEA%20DRAGONS%20AQUATIC%20ACADEMY%20SDN%20BHD!5e0!3m2!1sen!2smy!4v1584968664751!5m2!1sen!2smy';

const Section = styled.section`
  position: relative;
  background: #c5dbf2;
  h1 {
    font-size: 2rem;
    color: #241c15;
    text-align: center;
  }
`;

const GoogleMap = styled.div`
  min-height: 400px;
  height: 100%;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  background: #edf2f7;
  iframe {
    width: 100%;
    min-height: 400px;
    height: 100%;
    border: none;
  }
`;

const Grid = styled.div`
  #grid-details {
    grid-area: details;
  }
  #grid-map {
    grid-area: map;
  }
  grid-template-areas:
    'details'
    'map';
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  padding: 1rem 0;
  grid-column-gap: 1rem;
  @media (min-width: 860px) {
    padding: 4rem 0;
    grid-template-areas: 'map details';
    grid-template-columns: 2fr 1fr;
  }
`;

const Details = styled.div`
  max-width: 360px;
  margin: 0 auto;
  padding: 2rem 1rem;
  hr {
    border: 2px #ffffff solid;
  }
`;

const SocialMediaContainer = styled.div`
  padding: 1.5rem 0 2rem;
  max-width: 120px;
  margin: 0 auto;
  justify-content: space-around;
  display: flex;
  svg {
    height: 20px;
    fill: #000000;
  }
`;

const DetailBody = styled.div`
  padding: 2rem 0;
  h2 {
    font-weight: 500;
    font-size: 1.25rem;
  }
  svg {
    width: 18px;
    fill: #000000;
    margin: 0.5rem 1.5rem 0 0;
  }
`;

const Hours = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
  font-size: 0.985rem;
`;

const Map = () => {
  //
  const [location, setLocation] = useState(locationSDAA);

  const { businessInfo } = useStaticQuery(QUERY);

  const {
    contact: {
      email,
      address: { streetAddress, addressLocality, addressRegion, postalCode, addressCountryLong },
      telephone,
      whatsapp,
      googleMap,
    },
    business: { hours },
    socialMedia: { facebook, instagram, youtube },
  } = businessInfo.siteMetadata;

  return (
    <Section>
      <Grid>
        <div id="grid-details">
          <Details>
            <h1>Find Us</h1>
            <SocialMediaContainer>
              <a href={facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                <Facebook />
              </a>
              <a href={instagram} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                <Instagram />
              </a>
              <a href={youtube} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                <YouTube />
              </a>
            </SocialMediaContainer>
            <hr />
            <DetailBody>
              <div className="flex mb-5">
                <div>
                  <GoogleMapPin />
                </div>
                <div>
                  <h2>Address</h2>
                  {streetAddress},
                  <br />
                  {addressLocality},
                  <br />
                  {postalCode},
                  <br />
                  {addressRegion}, {addressCountryLong}.
                </div>
              </div>
              <div className="flex mb-5">
                <div>
                  <Clock />
                </div>
                <div>
                  <h2>Hours</h2>
                  {hours.map(hour => (
                    <Hours key={hour.day}>
                      <span>{hour.day}</span>
                      <span>{hour.duration}</span>
                    </Hours>
                  ))}
                </div>
              </div>
              <div className="flex mb-5">
                <div>
                  <Whatsapp />
                </div>
                <div>
                  <h2>WhatsApp</h2>
                  <div>{whatsapp}</div>
                </div>
              </div>
              <div className="flex mb-5">
                <div>
                  <Phone />
                </div>
                <div>
                  <h2>Call</h2>
                  <div>{telephone}</div>
                </div>
              </div>
              {/* <div className="flex mb-5">
                <div>
                  <Email />
                </div>
                <div>
                  <h2>Email</h2>
                  <div>{email}</div>
                </div>
              </div> */}
            </DetailBody>
          </Details>
        </div>
        <div id="grid-map">
          <GoogleMap>
            <iframe title="map" src={location} />
          </GoogleMap>
        </div>
      </Grid>
    </Section>
  );
};

export default Map;

Map.propTypes = {
  //
};

Map.defaultProps = {
  //
};
